// Abstracts
@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/functions";

// Vendors
@import "vendors/bootstrap";
@import "~leeloo-react/scss/components";
@import "~@iconscout/unicons/css/line.css";
@import "~@splidejs/splide/dist/css/splide.min.css";
@import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
@import "react-calendar/dist/Calendar.css";
@import "@splidejs/splide/dist/css/splide-core.min.css";
@import 'owl.carousel/dist/assets/owl.carousel.css';
@import 'owl.carousel/dist/assets/owl.theme.default.css';

// Utils
@import "utils/helpers";

// Base
@import "base/base";
@import "base/fonts";
@import "base/links";
@import "base/medias";
@import "base/typography";

// Layout
@import "layout/layout";
@import "layout/forms";

// Components
@import "components/attestation-filters";
@import "components/attestation-line";
@import "components/banner";
@import "components/bot-input-form";
@import "components/categories-navigator";
@import "components/cards-grid";
@import "components/card-unit";
@import "components/datepicker";
@import "components/dropdown";
@import "components/dt";
@import "components/formation-card";
@import "components/formation-details";
@import "components/formation-header";
@import "components/formation-session";
@import "components/formation-ua";
@import "components/ia-bot";
@import "components/modal";
@import "components/pagination";
@import "components/popup";
@import "components/react-select";
@import "components/sidebar";
@import "components/square";
@import "components/thematic-carousel";
@import "components/rating";
@import "components/badge";
@import "components/search-head";

// Pages
@import "pages/elearning";
@import "pages/dashboard";
@import "pages/profile";
@import "pages/thematic";
@import "pages/user";

// Import de la css de l'app (à nettoyer puis supprimer)
@import "tmp";
