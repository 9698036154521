.bot-input-page-wrapper {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.bot-input-form {
  position: relative;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    border-radius: 50%;
    width: 2.375rem;
    height: 2.375rem;
    position: absolute;
    left: .5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    background-color: $white;
    font-size: 1rem;
    border-radius: 2rem;
    padding: .875rem 1.5rem .875rem 3.875rem;
    color: $dark;
    width: 100%;

    &::placeholder {
      font-style: normal;
      font-weight: 500;
      color: rgba(#0F0A30, .4);
    }

  }

}

@include media-breakpoint-down(xl) {

  .bot-input-page-wrapper {
    display: none;
  }

}
